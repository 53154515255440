import React, { useState, useEffect } from 'react';
import { BrowserRouter, Link, Route, Routes, useLocation, useRoutes  } from "react-router-dom";
import Logo from '../Assets/logoblanco-bou.png'
import { Contactocomponente } from "./Contactocomponente";
import Imagenubi from '../Assets/image 81.png';
import Instagram from '../Assets/ig-boulder.png'
import Wpp from '../Assets/wpp-pau.png'
import "react-multi-carousel/lib/styles.css";
import Mailvector from '../Assets/Vector-mail.png'
import Telefonovector from '../Assets/Vector-telefono.png'
import Ubivector from '../Assets/vector-ubicacion.png'
import ButtonFixedWpp from "./Wpp";



const Navbar = () => {
    const [isOpen, setIsOpen]=useState(false)

      return (
        <div className='myTab'>
        <nav className="nav">
        <div className='imagen-home--'>
        <div className="home-nav-logo">
            <Link to="/">
              <img src= {Logo} alt="" className="logoppal"/> 
            </Link>
            <div className={`navbar-links-container ${isOpen && "open"}`}>
              <ul className="ul-nav">
                <li className="link-nav2" id="contacto"><Link className="li" to="/servicios">SERVICIOS</Link></li>
                <li className="link-nav2" id="nosotros"><Link className="li" to="/proyectos">PROYECTOS</Link></li>
                <li className="link-nav2" id="contacto"><Link className="li" to="/contacto">CONTACTO</Link></li>
              </ul>
              <ul className="ul-nav-redes">
              <a href="https://www.instagram.com/boulder.constructora/"target="_blank">
                <li className="link-nav2" id="contacto"><img src= {Instagram} alt="" className="redes-nav"/> </li>
                </a>
                <a href="https://wa.me/5493875311148"target="_blank">
                <li className="link-nav2" id="nosotros"><Link className="li"><img src= {Wpp} alt="" className="redes-nav"/></Link></li>
                </a>
                

              </ul>
            </div>
        </div>
          <div className="logo-toggle">
          <Link to="/">
          <img src= {Logo} alt="" className="logoppal2"/> 
          </Link>
        <div className={`toggle ${isOpen && "open"}`} onClick={()=>setIsOpen(!isOpen)}>
        <span className="span-nav"></span>
        <span className="span-nav"></span>
        <span className="span-nav"></span>

        <body className={isOpen ? 'overlay' : ''}></body>
        </div>
        </div>
        </div>
        
<div className="contacto-container2">
<div className="todo-elcontacto2">
<div className="titulo-contacto2">
<p className="titulo-pequeño2">CONTACTO</p>
<h1 className="titulo-grande2">GRACIAS POR TU INTERÉS<br></br> EN NUESTRA EMPRESA</h1> 
<p className="titulo-pequeño22">Contáctate con nosotros y recibí asesoramiento personalizado</p>
</div>
<Contactocomponente/>
</div>
<hr className="linea-cont"></hr>

<div className="contacto-redes-iconos2">
<h2 className="titulo-vector-contacto2">También podés contactarte por:</h2>

            <div className="cuadrito-contacto">
              <h2 className="titulo-vector-contacto"><img src= {Mailvector} alt="" className="vector-contacto"/>Email</h2>
              <p className="parrafo-vector-contacto2">atapia@boulder.ar / rfiore@boulder.ar</p>
            </div>

            <div className="cuadrito-contacto">
            <h2 className="titulo-vector-contacto"><img src= {Telefonovector} alt="" className="vector-contacto"/>Teléfono</h2>
              <p className="parrafo-vector-contacto2">+3875311148</p></div>

            <div className="cuadrito-contacto">
            <h2 className="titulo-vector-contacto"><img src= {Ubivector} alt="" className="vector-contacto"/>Ubicación</h2>
              <p className="parrafo-vector-contacto2">Autopista Circunvalación Oeste – Club de Campo La Arbolada</p>
            </div>
          </div>
</div>
<a href="https://maps.app.goo.gl/53UQSZRLz72QeEdU7"target="_blank">
<div className="ubicación"><img src= {Imagenubi} alt="" className="imagen-ubi"/></div>
</a>
<ButtonFixedWpp/>

<hr></hr>
<div className='todo-footer'>
<p className='copyright2'>© 2024 Boulder - Todos los derechos reservados.</p>
</div>
</nav>
</div>
               
  )
}

export default Navbar;
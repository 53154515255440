import React, { useState, useEffect } from 'react';
import { BrowserRouter, Link, Route, Routes, useLocation, useRoutes  } from "react-router-dom";
import Logo from '../Assets/logoblanco-bou.png'
import { Contactocomponente } from "./Contactocomponente";
import ButtonFixedWpp from "./Wpp";
import Siguiente from '../Assets/Vectorsiguiente.png';
import Anterior from '../Assets/Vectoranterior.png';
import Logocard1 from '../Assets/movimientodelsuelo.png';
import Logocard2 from '../Assets/infraestructura.png';
import Logocard3 from '../Assets/hidraulica.png';
import Eficiencia from '../Assets/eficiencia-circulo.png';
import Calidad from '../Assets/calidad-circulo.png';
import Equipo from '../Assets/apoyar-circulo.png';
import Logocard4 from '../Assets/arquitectura.png';
import logo1 from '../Assets/logo1-bou.png';
import logo2 from '../Assets/logo2-bou.png';
import logo3 from '../Assets/logo3-boucor.png';
import logo4 from '../Assets/logo4-bou.png';
import logo5 from '../Assets/logomuni.png';
import Img1 from '../Assets/carruselhome1.png';
import Img2 from '../Assets/carruselhome2.png';
import Img3 from '../Assets/carruselhome3.png';
import Img4 from '../Assets/carruselhome4.png';
import Img5 from '../Assets/carruselhome5.png';
import Img6 from '../Assets/carruselhome6.png';

import Carousel from 'react-multi-carousel';
import Instagram from '../Assets/ig-boulder.png'
import Wpp from '../Assets/wpp-pau.png'

import "react-multi-carousel/lib/styles.css";
import Mailvector from '../Assets/Vector-mail.png'
import Telefonovector from '../Assets/Vector-telefono.png'
import Ubivector from '../Assets/vector-ubicacion.png'



const LogosCarousel = () => {
  const logos = [logo1, logo2, logo3, logo4, logo5];
  const responsive = {
      superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
      },
      desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
      },
      tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
          partialVisibilityGutter: 10 
      },
      mobile: {
          breakpoint: { max: 853, min: 0 },
          items: 2,
          partialVisibilityGutter: 10
      },
      mobile: {
        breakpoint: { max: 540, min: 0 },
        items: 1,
        partialVisibilityGutter: 10
    }
  }
  return (
    <Carousel 
    responsive={responsive}
    autoPlay={true}
    autoPlaySpeed={2000}
    infinite={true}
  >
    {logos.map((logo, index) => (
      <img key={index} src={logo} alt={`Logo ${index + 1}`} className='logo-w' />
    ))}
  </Carousel>
);

};

const Navbar = () => {
    const [isOpen, setIsOpen]=useState(false)
    const [indexB, setIndexB] = useState(0);

    const [cardsPerPage, setCardsPerPage] = useState(2); // Por defecto, muestra 2 cards por página
    const [startX, setStartX] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1240) {
        setCardsPerPage(1); // Si la pantalla es de 600px o menos, muestra 1 card
      } else {
        setCardsPerPage(2); // Si la pantalla es mayor a 600px, muestra 2 cards
      }
    };

    // Escucha los cambios en el tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Llama a la función una vez al montar el componente para establecer el valor inicial
    handleResize();

    // Limpia el event listener al desmontar el componente
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  
    const cardsA = [
      { src: Img1 },
      { src: Img2 },
      { src: Img3 },
      { src: Img4 },
      { src: Img5 },
      { src: Img6 },


    ] ;

    const next = () => {
      setIndexB((prevIndex) => {
        if (prevIndex + 1 >= cardsA.length) {
          return 0; // Vuelve al principio
        } else {
          return prevIndex + 1; // Mueve el índice 3 tarjetas hacia adelante
        }
      });
    };
    
    const prev = () => {
      setIndexB((prevIndex) => Math.max(prevIndex - 2, 0)); // Mueve el índice 3 tarjetas hacia atrás, pero no menos de 0
    };
    // Manejadores para eventos táctiles y del mouse
    const handleStart = (clientX) => {
      setStartX(clientX);
    };
    
    const handleMove = (clientX) => {
      if (startX === null) {
        return;
      }
    
      const diff = startX - clientX;
    
      if (diff > 5) {
        next();
      } else if (diff < -5) {
        prev();
      }
    
      setStartX(null);
    };
    
    // Eventos táctiles
    const handleTouchStart = (e) => {
      handleStart(e.touches[0].clientX);
    };
    
    const handleTouchMove = (e) => {
      handleMove(e.touches[0].clientX);
    };
    
    // Eventos del mouse
    const handleMouseDown = (e) => {
      handleStart(e.clientX);
    };
    
    const handleMouseMove = (e) => {
      if (e.buttons === 1) { // Solo si el botón izquierdo está presionado
        handleMove(e.clientX);
      }
    };
    
    const handleMouseUp = () => {
      setStartX(null);
    };



      return (
        <nav className="nav">
        <div className='imagen-home'>
        <div className="home-nav-logo">
            <Link to="/">
              <img src= {Logo} alt="" className="logoppal"/> 
            </Link>
            <div className={`navbar-links-container ${isOpen && "open"}`}>
              <ul className="ul-nav">
                <li className="link-nav2" id="contacto"><Link className="li" to="/servicios">SERVICIOS</Link></li>
                <li className="link-nav2" id="nosotros"><Link className="li" to="/proyectos">PROYECTOS</Link></li>
                <li className="link-nav2" id="contacto"><Link className="li" to="/contacto">CONTACTO</Link></li>
              </ul>
              <ul className="ul-nav-redes">
              <a href="https://www.instagram.com/boulder.constructora/"target="_blank">
                <li className="link-nav2" id="contacto"><img src= {Instagram} alt="" className="redes-nav"/> </li>
                </a>
                <a href="https://wa.me/5493875311148"target="_blank">
                <li className="link-nav2" id="nosotros"><Link className="li"><img src= {Wpp} alt="" className="redes-nav"/></Link></li>
                </a>
                
              </ul>
            </div>
        </div>
          <div className="logo-toggle">
          <Link to="/">
          <img src= {Logo} alt="" className="logoppal2"/> 
          </Link>
        <div className={`toggle ${isOpen && "open"}`} onClick={()=>setIsOpen(!isOpen)}>
        <span className="span-nav"></span>
        <span className="span-nav"></span>
        <span className="span-nav"></span>

        <body className={isOpen ? 'overlay' : ''}></body>
        </div>
        </div>
        <div className='container-hero-home'>
        <h1 className='hero-titulo-home'>Transformamos el futuro de la obra con excelencia y calidad</h1>
        <h2 className='hero-subt-home'>Conocé Boulder</h2>
        </div>
        </div>
        
      <div className='titulo-cardscontainer'>
        <h2 className='titulo-cards'>Conocé Boulder y llevá tu obra al siguiente nivel</h2>
        <h2 className='titulo-cards-mas'>+ + + + +</h2>
        <div className='container-home-cards'>
          <div className="card-servicios">
            <div className="contenedor-circulo-logo">
            <div className="circulo-logo"><img src= {Logocard1} alt="" className="logo-card"/> </div>
            <h2 className="titulo-cardA">Movimiento del suelo</h2>
            <p className="text-card-servicios">Nos ocupamos de todas las actuaciones necesarias sobre el terreno, previo a la realización de cualquier obra, garantizando su correcto desarrollo en las etapas siguientes.</p>
            </div>
          </div>

        <div className="card-servicios">
          <div className="contenedor-circulo-logo">
          <div className="circulo-logo"><img src= {Logocard2} alt="" className="logo-card"/></div>
          <h2 className="titulo-cardB">Infraestructura vial urbana</h2>
          <p className="text-card-servicios">Realizamos todas las obras que constituyen la vía de circulación, y todos sus soportes que conforman la estructura de las carreteras y caminos.</p>
            </div>
          </div>

        <div className="card-servicios">
          <div className="contenedor-circulo-logo">
          <div className="circulo-logo"><img src= {Logocard3} alt="" className="logo-card"/></div>
          <h2 className="titulo-cardC">Infraestructura hidráulica</h2>
          <p className="text-card-servicios">Ejecutamos redes de agua y redes cloacales, desde la excavación inicial, la ejecución de la obra y la puesta en servicio.</p>
            </div>
          </div>

        <div className="card-servicios">
          <div className="contenedor-circulo-logo">
          <div className="circulo-logo"><img src= {Logocard4} alt="" className="logo-card"/></div>
          <h2 className="titulo-cardD">Arquitectura y montajes</h2>
          <p className="text-card-servicios">Realizamos obras de arquitectura desde casas hasta edificios, brindamos soluciones de ingeniería aplicadas a diferentes industrias. También ejecutamos obras metálicas y montaje de galpones.</p>
            </div>
          </div>
        </div>
      </div>


      <div className="carrusel-gris-img">
            <div className="cartelito-contenedor">
              <div className="text-container-cartelito">
            <p className="titulo-pequeño">SOBRE NOSOTROS</p>
            <h1 className="titulo-grande">NUESTRA HISTORIA</h1>
            <p className="parrafo-home-carruselimg">
            Ante las continuas transformaciones y cambiantes necesidades del mercado de la construcción se crea <spam className="negrita">Boulder</spam>. Iniciativa liderada por <spam className="negrita">Sebastián Achaval </spam>(GEOMIX), y el Ingeniero <spam className="negrita">Alejandro Tapia</spam>, a los fines de <spam className="negrita">dar soluciones viales</spam> a la demanda actual con calidad y confianza.
<br></br>
<br></br>
Actualmente, Boulder se encuentra en una etapa de <spam className="negrita">crecimiento y evolución</spam>, buscando brindar las mejores soluciones a todos nuestros clientes, en base a nuestros valores, ofreciendo la <spam className="negrita">calidad</spam>, la <spam className="negrita">responsabilidad </spam>y la <spam className="negrita">eficiencia</spam> que nos caracterizan.
            </p>
            </div>
            <div className='circulos-carrusel-home'>
            <div className="circulo-carrusel"><img src= {Eficiencia} alt="" className="circulo-img"/>
            <p className="titulo-circulo">EFICIENCIA</p>
            </div>
            <div className="circulo-carrusel"><img src= {Calidad} alt="" className="circulo-img"/>
            <p className="titulo-circulo">CALIDAD</p></div>
            <div className="circulo-carrusel"><img src= {Equipo} alt="" className="circulo-img"/>
            <p className="titulo-circulo">EQUIPO</p>
            </div>
            </div>
            </div>

            <div className="carrusel-am">
            <div className="cards-carruselam3"
             onTouchStart={handleTouchStart}
             onTouchMove={handleTouchMove}
             onMouseDown={handleMouseDown}
             onMouseMove={handleMouseMove}
             onMouseUp={handleMouseUp}
             onMouseLeave={handleMouseUp} >
            {cardsA.slice(indexB, indexB + 1).map((card, i) => (
    <div key={i} className="card-nosotros-obras">
      <img src={card.src} alt="" className="img-nosotros2"/>   
    </div>
  ))}

  

  <div className="indicadores-circulos">
    {cardsA.map((_, idx) => (
      <span
        key={idx}
        className={`circulo ${indexB === idx ? 'activo' : ''}`}
        onClick={() => setIndexB(idx)}
      ></span>
    ))}
  </div>
</div>
        </div>
        </div>

        <div className='recuadros-proyectos'>
            <div className='container-txt-proyectos-home'>
            <p className="titulo-pequeño">UN VISTAZO A NUESTRO TRABAJO</p>
            <h1 className="titulo-grande">NUESTROS PROYECTOS</h1>
            <p className="parrafo-home-carruselimg">Te presentamos algunos de los proyectos destacados que realizamos en Boulder.</p>
            </div>
            <div className='cuadros-proyectos-container'>
              <div className='foto-ppal-cuadros-proyectos'>
                <h3 className='titulo-cuadros-proyectos'>Las Liebres</h3>
                
              </div>
              <div className='foto-chiquita-cuadros-proyectos'>
                <div className='dosfotos'>
                  <div className='foto-chiquita'>
                  <h3 className='titulo-cuadros-proyectosB'>Los Nogales</h3>
                  </div>
                  <div className='foto-chiquita2'>
                  <h3 className='titulo-cuadros-proyectosB'>Ciudad Oeste</h3>
                  </div>
                </div>
                <div className='dosfotos'>
                <div className='foto-chiquita3'>
                <h3 className='titulo-cuadros-proyectosB'>Casonas El Retiro</h3>
                </div>
                <div className='foto-chiquita4'>
                <h3 className='titulo-cuadros-proyectosB'>La Trinidad</h3>
                </div>
                </div>
              </div>
              
            </div>
            <Link to="/proyectos">
            <button className="boton-home" type="submit" value="Send" >VER TODAS LAS OBRAS</button>
            </Link>
        </div>
    
   
        <div className="carrusel-marcas">
        <p className="titulo-pequeño">NUESTROS ALIADOS</p>
        <h1 className="titulo-grande">CONFIAN EN NOSOTROS</h1> 
        </div> 
       <div className="logos-carrusel">
         <LogosCarousel /> 
        </div> 



       
        





<div className="contacto-container">
<div className="todo-elcontacto">
<div className="titulo-contacto">
<p className="titulo-pequeño">CONTACTO</p>
<h1 className="titulo-grande">¿TE QUEDASTE CON DUDAS?</h1> 
<p className="parrafo-home-carruselimg-contacto">Contáctate con nosotros y recibí asesoramiento personalizado</p>
<div className="contacto-redes-iconos">

            <div className="cuadrito-contacto">
              <p className="parrafo-vector-contacto"><img src= {Mailvector} alt="" className="vector-contacto"/>atapia@boulder.ar / rfiore@boulder.ar</p>
            </div>

            <div className="cuadrito-contacto">
              <p className="parrafo-vector-contacto"><img src= {Telefonovector} alt="" className="vector-contacto"/>+3875311148</p></div>

            <div className="cuadrito-contacto">
              <p className="parrafo-vector-contacto"><img src= {Ubivector} alt="" className="vector-contacto"/>Autopista Circunvalación Oeste – Club de Campo La Arbolada</p>
            </div>
          </div>
</div>

<Contactocomponente/>
</div>
</div>



<hr></hr>
<div className='todo-footer'>
<p className='copyright'>© 2024 Boulder - Todos los derechos reservados.</p>
</div>
<ButtonFixedWpp/>
</nav>
               
  )
}

export default Navbar;
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Logo from '../Assets/logoblanco-bou.png'
import { Contactocomponente } from "./Contactocomponente";
import "react-multi-carousel/lib/styles.css";
import Mailvector from '../Assets/Vector-mail.png'
import Telefonovector from '../Assets/Vector-telefono.png'
import Ubivector from '../Assets/vector-ubicacion.png'
import Instagram from '../Assets/ig-boulder.png'
import Wpp from '../Assets/wpp-pau.png';
import ButtonFixedWpp from "./Wpp";
import Img1 from '../Assets/ciudadoeste.png';
import Img2 from '../Assets/plantadepuradora.png'
import Img3 from '../Assets/casonaelretiro.png'
import Img4 from '../Assets/porto.png'
import Img5 from '../Assets/latrinidad.png'
import Img6 from '../Assets/laserenisima.png'
import Img7 from '../Assets/barriolaschuñas.png'
import Img8 from '../Assets/hiltonsanlorenzochico.png'
import Img9 from '../Assets/jardinessanlorenzochico.png'
import Img10 from '../Assets/quebradadelosnogales.png'
import Img11 from '../Assets/lasliebres.png'
import Img12 from '../Assets/gardenpark.png'



const Navbar = () => {
    const initialCardsToShow = 6; // Cantidad inicial de tarjetas a mostrar
  const [cardsToShow, setCardsToShow] = useState(initialCardsToShow);

  const cardData = [{
    src: Img1,
    title: 'Ciudad Oeste',
    description: 'Emparejado de rasante e imprimación. Tratamiento doble asfaltico con riego adicional y arenado',
  },

  {
    src: Img2,
    title: 'Planta Depuradora Sur',
    description: 'Provisión de suelo para rellenos y terraplenes. Compactación del suelo',
  },

  {
    src: Img3,
    title: 'Casonas el Retiro',
    description: 'Recambio de suelo, ejecución de cuneta de Hº Perfilado  y relleno de calzadas. Ejecución de tratamiento bituminoso doble',
  },

  {
    src: Img4,
    title: 'Porto',
    description: 'Ejecución de perfilado. Compactación de calzada. Tratamiento Bituminoso doble',
  },

  {
    src: Img5,
    title: 'La Trinidad',
    description: 'Ejecución de taludes perimetrales',
  },
  {
    src: Img6,
    title: 'La Serenísima',
    description: 'Limpieza y perfilado de calle de ingreso. Excavacion Gruesa',
  },
  {
    src: Img7,
    title: 'Barrio las Chuñas',
    description: 'Apertura de calzada y ejecución paquete estructural. Tratamiento doble asfaltico, cordón emergente montante, ejecución de alcantarilla y relleno de teraplen',
  },
  {
    src: Img12,
    title: 'Garden Park',
    description: 'Tratamiento bituminoso doble',
  },
  
  {
    src: Img9,
    title: 'Jardines San Lorenzo Chico',
    description: 'Tratamiento bituminoso doble',
  },
  {
    src: Img10,
    title: 'Quebrada de los Nogales',
    description: 'Tratamiento bituminoso doble',
  },
  {
    src: Img11,
    title: 'Las Liebres',
    description: 'Tratamiento bituminoso doble',
  },
  {
    src: Img8,
    title: 'Hilton San Lorenzo Chico',
    description: 'Apertura de calzada y ejecución paquete estructural. Tratamiento doble asfaltico. Cordón emergente montante',
  },
 


] ;

const allCardsShown = cardsToShow >= cardData.length;
    const [isOpen, setIsOpen]=useState(false)
    const [indexA, setIndexA] = useState(0); // Para el primer carrusel
    const [indexB, setIndexB] = useState(0); // Para el segundo carrusel


    const [cardsPerPage, setCardsPerPage] = useState(2); // Por defecto, muestra 2 cards por página
    const [startX, setStartX] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setCardsPerPage(1); // Si la pantalla es de 600px o menos, muestra 1 card
      } else {
        setCardsPerPage(2); // Si la pantalla es mayor a 600px, muestra 2 cards
      }
    };

    // Escucha los cambios en el tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Llama a la función una vez al montar el componente para establecer el valor inicial
    handleResize();

    // Limpia el event listener al desmontar el componente
    return () => window.removeEventListener('resize', handleResize);
  }, []);






        
      return (
        <nav className="nav">
          <div className='imagen-servicios-proyectos'>
        <div className="contenedor-carrusel-servicios">    
        <div className="home-nav-logo">
        <Link to="/">
        <img src= {Logo} alt="" className="logoppal"/> 
        </Link>

            <div className={`navbar-links-container ${isOpen && "open"}`}>
              <ul className="ul-nav">
                <li className="link-nav2" id="contacto"><Link className="li" to="/servicios">SERVICIOS</Link></li>
                <li className="link-nav2" id="nosotros"><Link className="li" to="/proyectos">PROYECTOS</Link></li>
                <li className="link-nav2" id="contacto"><Link className="li" to="/contacto">CONTACTO</Link></li>
              </ul>
              <ul className="ul-nav-redes">
              <a href="https://www.instagram.com/boulder.constructora/"target="_blank">
                <li className="link-nav2" id="contacto"><img src= {Instagram} alt="" className="redes-nav"/> </li>
                </a>
                <a href="https://wa.me/5493875311148"target="_blank">
                <li className="link-nav2" id="nosotros"><Link className="li"><img src= {Wpp} alt="" className="redes-nav"/></Link></li>
                </a>
               
              </ul>
            </div>
        </div>
          <div className="logo-toggle">
          <Link to="/">
          <img src= {Logo} alt="" className="logoppal2"/> 
          </Link> 
        <div className={`toggle ${isOpen && "open"}`} onClick={()=>setIsOpen(!isOpen)}>
        <span className="span-nav"></span>
        <span className="span-nav"></span>
        <span className="span-nav"></span>

        <body className={isOpen ? 'overlay' : ''}></body>
        </div>
        </div>
        

        
    
            <div className="container-txt-carruselppal">
            <div className="text-red-ppal">
            <p className="titulo-pequeño-servicios">BOULDER</p>
            <h1 className="titulo-grande-servicios">PROYECTOS</h1>
            <p className="parrafo-pequeño-servicios">Te presentamos los proyectos realizados por equipo Boulder.</p>
            <div className="boton-mediaq">
            <Link to="/contacto">
            <button className="boton-contacto">PEDIR UNA COTIZACIÓN</button>
            </Link>
            </div>
            </div>
     

          
             
              
        </div>
        </div>
        </div>
        
        <div className="titulos-seccion-comentarios2">
<h1 className="titulo-grande">OBRAS BOULDER</h1> 
<p className="titulo-pequeño-comen-servicios">Conocé nuestros proyectos de principio a fin.</p>
</div>
        
        <div className="cards-obras-nuestro">

<div className="container-cards-nosotros">
{cardData.slice(0, cardsToShow).map((card, index) => (
<Link to={card.url} key={index} className='a-card'> {/* Envuelve tu tarjeta con Link */}
<div className="card-nosotros">
<img src={card.src} alt="" className="img-nosotros-obras" />
<hr className="linea-coment3-trabajos" />
<h2 className="nombre-trabajos">{card.title}</h2>
<p className="txt-card-trabajos">
<span className="ar">{card.description}</span>
</p>
</div>
</Link>
))}

</div>
{!allCardsShown && (
<button onClick={() => setCardsToShow(cardsToShow + 6)} className="vermas-nosotros">VER MÁS</button>
)}



</div>

        {/* <div className="container-comentarios">

        <div className="titulos-seccion-comentarios">
        <p className="titulo-pequeño-comen">TESTIMONIOS</p>
        <h1 className="titulo-grande">NUESTROS CLIENTES</h1> 
        <p className="titulo-pequeño-comen">Conocé la experiencia de algunos de nuestros clientes que confiaron en Boulder.</p>
        </div>

        <div className="cards-comentarios">
       
          <div className="coment">
          <p className="comilla">"</p>
          <p className="titulo-pequeño-comen1">Excelente trabajo, Todo el equipo muy <spam className="ar">atento y responsable, </spam>con lo cual el resultado final es espectacular!”</p>
          <p className='lineademas'>+ + + + +</p>
          <h3 className="titulo-nombre">Fiorela Gallucci</h3>
          <p className="titulo-pequeño-comen">Localidad, producto</p>
          </div>

          <div className="coment">
          <p className="comilla">"</p>
          <p className="titulo-pequeño-comen1">Profesionales, pro activos y con ganas de crecer, <spam className="ar">super recomendable”</spam></p>
          <p className='lineademas'>+ + + + +</p>
          <h3 className="titulo-nombre">Juan Nicolás Pintos</h3>
          <p className="titulo-pequeño-comen">Localidad, producto</p>
          </div>

          <div className="coment">
          <p className="comilla">"</p>
          <p className="titulo-pequeño-comen1">Pintaron mi casa y se nota la diferencia totalmente, <spam className="ar">es un servicio 100% profesional, </spam>lo hicieron en dos días”</p>
          <p className='lineademas'>+ + + + +</p>
          <h3 className="titulo-nombre">Silvina Basualdo</h3>
          <p className="titulo-pequeño-comen">Localidad, producto</p>
          </div>
        </div>

      </div> */}

<div className="contacto-container">
<div className="todo-elcontacto">
<div className="titulo-contacto">
<p className="titulo-pequeño">CONTACTO</p>
<h1 className="titulo-grande">¿TE QUEDASTE CON DUDAS?</h1> 
<p className="parrafo-home-carruselimg">Contáctate con nosotros y recibí asesoramiento personalizado</p>
<div className="contacto-redes-iconos">

            <div className="cuadrito-contacto">
              <p className="parrafo-vector-contacto"><img src= {Mailvector} alt="" className="vector-contacto"/>atapia@boulder.ar / rfiore@boulder.ar</p>
            </div>

            <div className="cuadrito-contacto">
              <p className="parrafo-vector-contacto"><img src= {Telefonovector} alt="" className="vector-contacto"/>+3875311148</p></div>

            <div className="cuadrito-contacto">
              <p className="parrafo-vector-contacto"><img src= {Ubivector} alt="" className="vector-contacto"/>Autopista Circunvalación Oeste – Club de Campo La Arbolada</p>
            </div>
          </div>
</div>

<Contactocomponente/>
</div>
</div>

<ButtonFixedWpp/>

<hr></hr>
<div className='todo-footer'>
<p className='copyright'>© 2024 Boulder - Todos los derechos reservados.</p>
</div>
</nav>
               
  )
}

export default Navbar;
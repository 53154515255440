import React, { useState, useEffect } from 'react';
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Logo from '../Assets/logoblanco-bou.png'
import { Contactocomponente } from "./Contactocomponente";
import Siguiente from '../Assets/siguiente-bou.png';
import Anterior from '../Assets/anterior-bou.png';
import Logocard1 from '../Assets/movimientodelsuelo.png';
import Logocard2 from '../Assets/infraestructura.png';
import Logocard3 from '../Assets/hidraulica.png';
import Img1 from '../Assets/ciudadoeste.png';
import Img2 from '../Assets/plantadepuradora.png'
import Img3 from '../Assets/casonaelretiro.png'
import Img4 from '../Assets/porto.png'
import Img5 from '../Assets/latrinidad.png'
import Img6 from '../Assets/laserenisima.png'
import Img7 from '../Assets/barriolaschuñas.png'
import Img8 from '../Assets/hiltonsanlorenzochico.png'
import Img9 from '../Assets/jardinessanlorenzochico.png'
import Img10 from '../Assets/quebradadelosnogales.png'
import Img11 from '../Assets/lasliebres.png'
import Img12 from '../Assets/gardenpark.png'
import "react-multi-carousel/lib/styles.css";
import Mailvector from '../Assets/Vector-mail.png'
import Telefonovector from '../Assets/Vector-telefono.png'
import Ubivector from '../Assets/vector-ubicacion.png'
import Instagram from '../Assets/ig-boulder.png'
import Wpp from '../Assets/wpp-pau.png'
import ButtonFixedWpp from "./Wpp";





const Navbar = () => {
    const [isOpen, setIsOpen]=useState(false)
    const [index, setIndex] = useState(0);
  const [cardsPerPage, setCardsPerPage] = useState(2);

  useEffect(() => {
    const handleResize = () => {
      console.log('handleResize se está ejecutando');
      if (window.innerWidth <= 900) {
        setCardsPerPage(1);
      } else if (window.innerWidth <= 1919) {
        setCardsPerPage(2);
      } else {
        setCardsPerPage(3);
      }
    };
    

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextA = () => {
    setIndex((prevIndex) => (prevIndex + cardsPerPage) % cardsA.length);
  };

  const prevA = () => {
    setIndex((prevIndex) => (prevIndex - cardsPerPage + cardsA.length) % cardsA.length);
  };

  
    const cardsA = 
      [{
        src: Img1,
        title: 'Ciudad Oeste',
        description: 'Emparejado de rasante e imprimación. Tratamiento doble asfaltico con riego adicional y arenado',
      },
    
      {
        src: Img2,
        title: 'Planta Depuradora Sur',
        description: 'Provisión de suelo para rellenos y terraplenes. Compactación del suelo',
      },
    
      {
        src: Img3,
        title: 'Casonas el Retiro',
        description: 'Recambio de suelo, ejecución de cuneta de Hº Perfilado  y relleno de calzadas. Ejecución de tratamiento bituminoso doble',
      },
    
      {
        src: Img4,
        title: 'Porto',
        description: 'Ejecución de perfilado. Compactación de calzada. Tratamiento Bituminoso doble',
      },
    
      {
        src: Img5,
        title: 'La Trinidad',
        description: 'Ejecución de taludes perimetrales',
      },
      {
        src: Img6,
        title: 'La Serenísima',
        description: 'Limpieza y perfilado de calle de ingreso. Excavacion Gruesa',
      },
      {
        src: Img7,
        title: 'Barrio las Chuñas',
        description: 'Apertura de calzada y ejecución paquete estructural. Tratamiento doble asfaltico, cordón emergente montante, ejecución de alcantarilla y relleno de teraplen',
      },
      {
        src: Img12,
        title: 'Garden Park',
        description: 'Tratamiento bituminoso doble',
      },
      
      {
        src: Img9,
        title: 'Jardines San Lorenzo Chico',
        description: 'Tratamiento bituminoso doble',
      },
      {
        src: Img10,
        title: 'Quebrada de los Nogales',
        description: 'Tratamiento bituminoso doble',
      },
      {
        src: Img11,
        title: 'Las Liebres',
        description: 'Tratamiento bituminoso doble',
      },
      {
        src: Img8,
        title: 'Hilton San Lorenzo Chico',
        description: 'Apertura de calzada y ejecución paquete estructural. Tratamiento doble asfaltico. Cordón emergente montante',
      },
     


    ] ;

  




        
      return (
        <nav className="nav">
          <div className='imagen-servicios'>
        <div className="contenedor-carrusel-servicios">    
        <div className="home-nav-logo">
        <Link to="/">
        <img src= {Logo} alt="" className="logoppal"/> 
        </Link>

            <div className={`navbar-links-container ${isOpen && "open"}`}>
              <ul className="ul-nav">
                <li className="link-nav2" id="contacto"><Link className="li" to="/servicios">SERVICIOS</Link></li>
                <li className="link-nav2" id="nosotros"><Link className="li" to="/proyectos">PROYECTOS</Link></li>
                <li className="link-nav2" id="contacto"><Link className="li" to="/contacto">CONTACTO</Link></li>
              </ul>
              <ul className="ul-nav-redes">
              <a href="https://www.instagram.com/boulder.constructora/"target="_blank">
                <li className="link-nav2" id="contacto"><img src= {Instagram} alt="" className="redes-nav"/> </li>
                </a>
                <a href="https://wa.me/5493875311148"target="_blank">
                <li className="link-nav2" id="nosotros"><Link className="li"><img src= {Wpp} alt="" className="redes-nav"/></Link></li>
                </a>
                
              </ul>
            </div>
        </div>
          <div className="logo-toggle">
          <Link to="/">
          <img src= {Logo} alt="" className="logoppal2"/> 
          </Link>
        <div className={`toggle ${isOpen && "open"}`} onClick={()=>setIsOpen(!isOpen)}>
        <span className="span-nav"></span>
        <span className="span-nav"></span>
        <span className="span-nav"></span>

        <body className={isOpen ? 'overlay' : ''}></body>
        </div>
        </div>
        

        
    
            <div className="container-txt-carruselppal">
            <div className="text-red-ppal">
            <p className="titulo-pequeño-servicios">BOULDER</p>
            <h1 className="titulo-grande-servicios">SERVICIOS</h1>
            <p className="parrafo-pequeño-servicios">Conocé todos los servicios que ofrecemos en Boulder .</p>
            <div className="boton-mediaq">
            <Link to="/contacto">
            <button className="boton-contacto">PEDIR UNA COTIZACIÓN</button>
            </Link>
            </div>
            </div>
            

          
             
              
        </div>
        </div>
        </div>
        

        <div className='container-home-cards'>
          <div className="card-servicios">
            <div className="contenedor-circulo-logo">
            <div className="circulo-logo"><img src= {Logocard1} alt="" className="logo-card"/> </div>
            <h2 className="titulo-cardA">Movimiento del suelo</h2>
            <p className="text-card-servicios">Nos ocupamos de todas las actuaciones necesarias sobre el terreno, previo a la realización de cualquier obra, garantizando su correcto desarrollo en las etapas siguientes.</p>
            </div>
          </div>

        <div className="card-servicios">
          <div className="contenedor-circulo-logo">
          <div className="circulo-logo"><img src= {Logocard2} alt="" className="logo-card"/></div>
          <h2 className="titulo-cardB">Infraestructura vial urbana</h2>
          <p className="text-card-servicios">Realizamos todas las obras que constituyen la vía de circulación, y todos sus soportes que conforman la estructura de las carreteras y caminos.</p>
            </div>
          </div>

        <div className="card-servicios">
          <div className="contenedor-circulo-logo">
          <div className="circulo-logo"><img src= {Logocard3} alt="" className="logo-card"/></div>
          <h2 className="titulo-cardC">Infraestructura hidráulica</h2>
          <p className="text-card-servicios">Ejecutamos redes de agua y redes cloacales, desde la excavación inicial, la ejecución de la obra y la puesta en servicio.</p>
            </div>
          </div>

        <div className="card-servicios">
          <div className="contenedor-circulo-logo">
          <div className="circulo-logo"><img src= {Logocard3} alt="" className="logo-card"/></div>
          <h2 className="titulo-cardD">Arquitectura y montajes</h2>
          <p className="text-card-servicios">Realizamos obras de arquitectura desde casas hasta edificios, brindamos soluciones de ingeniería aplicadas a diferentes industrias. También ejecutamos obras metálicas y montaje de galpones.</p>
            </div>
          </div>
        </div>

            <div className="carrusel-amarillo">
            <div className="cartelito-contenedor-carruselam">
              <div className="text-container-cartelito-carruselam">
            <p className="obras-letrachica">0BRAS</p>
            <h1 className="titulo-obras">+30</h1>
            <h2 className="subtitulo-obras">OBRAS <br></br>CONCLUIDAS</h2>
            <Link to="/proyectos">
            <button className="boton-cartelito">VER TODAS LAS OBRAS</button>
            </Link>
            </div>
            </div>
           <div className='cards-yflecha'>
        <div className="card-nosotros-obras-servicios">
         {cardsA.slice(index, index + cardsPerPage).map((card, i) => (
          <div key={i} className="card-nosotros-obras">
            <img src={card.src} alt="" className="img-nosotros-serv" width={350} height={248}/>
            <hr className='linea-carrusel'></hr>
            <h2 className='card-titulo'>{card.title}</h2>
            <p className='p-card-carru'>{card.description}</p>
            
          </div>
        ))}
        </div>
         
              <div className='flechas-carrusel'>
              <button onClick={prevA} className="boton-siguiente">
                <img src={Anterior} alt="" className="siguiente"/>
                </button>
                <button onClick={nextA} className="boton-siguiente">
                <img src={Siguiente} alt="" className="siguiente"/>
                </button>
                </div>
                </div>
                </div>
                
        
    
   



        {/* <div className="container-comentarios2">

<div className="titulos-seccion-comentarios2">
<p className="titulo-pequeño-comen2">TESTIMONIOS</p>
<h1 className="titulo-grande">NUESTROS CLIENTES</h1> 
<p className="titulo-pequeño-comen-servicios">Conocé la experiencia de algunos de nuestros clientes que confiaron en Boulder.</p>
</div>

<div className="cards-comentarios">

  <div className="coment">
  <p className="comilla">"</p>
  <p className="titulo-pequeño-comen2">Excelente trabajo, Todo el equipo muy <spam className="ar">atento y responsable, </spam>con lo cual el resultado final es espectacular!”</p>
  <p className='lineademas'>+ + + + +</p>
  <h3 className="titulo-nombre2">Fiorela Gallucci</h3>
  <p className="titulo-pequeño-comen">Localidad, producto</p>
  </div>

  <div className="coment">
  <p className="comilla">"</p>
  <p className="titulo-pequeño-comen2">Profesionales, pro activos y con ganas de crecer, <spam className="ar">super recomendable”</spam></p>
  <p className='lineademas'>+ + + + +</p>
  <h3 className="titulo-nombre2">Juan Nicolás Pintos</h3>
  <p className="titulo-pequeño-comen">Localidad, producto</p>
  </div>

  <div className="coment">
  <p className="comilla">"</p>
  <p className="titulo-pequeño-comen2">Pintaron mi casa y se nota la diferencia totalmente, <spam className="ar">es un servicio 100% profesional, </spam>lo hicieron en dos días”</p>
  <p className='lineademas'>+ + + + +</p>
  <h3 className="titulo-nombre2">Silvina Basualdo</h3>
  <p className="titulo-pequeño-comen">Localidad, producto</p>
  </div>
</div>

</div> */}

<div className="contacto-container">
<div className="todo-elcontacto">
<div className="titulo-contacto">
<p className="titulo-pequeño">CONTACTO</p>
<h1 className="titulo-grande">¿TE QUEDASTE CON DUDAS?</h1> 
<p className="parrafo-home-carruselimg">Contáctate con nosotros y recibí asesoramiento personalizado</p>
<div className="contacto-redes-iconos">

            <div className="cuadrito-contacto">
              <p className="parrafo-vector-contacto"><img src= {Mailvector} alt="" className="vector-contacto"/>atapia@boulder.ar / rfiore@boulder.ar</p>
            </div>

            <div className="cuadrito-contacto">
              <p className="parrafo-vector-contacto"><img src= {Telefonovector} alt="" className="vector-contacto"/>+3875311148</p></div>

            <div className="cuadrito-contacto">
              <p className="parrafo-vector-contacto"><img src= {Ubivector} alt="" className="vector-contacto"/>Autopista Circunvalación Oeste – Club de Campo La Arbolada</p>
            </div>
          </div>
</div>

<Contactocomponente/>
</div>
</div>

<ButtonFixedWpp/>

<hr></hr>
<div className='todo-footer'>
<p className='copyright'>© 2024 Boulder - Todos los derechos reservados.</p>
</div>
</nav>
               
  )
}

export default Navbar;